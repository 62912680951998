<template>

  <div class="radb">
 
    <template
      v-for="(item, item_key) in list"
      >
      <div 
        :class="'ritem' + (sel == item_key ? ' checked ' : '')"
        @click="choose(item_key)"
        >
        {{ item ? $t(item) : '' }}
      </div>
    </template>
    
    
  
  </div>
  
</template>


<script>

export default {
  components: {         
    
  },  
  props:[
    'list',
    'selected', 
  ],
  data() {
    return {
      sel: this.selected
    };
  }, 
  watch: {    
    selected: function(newVal, oldVal) {      
      // console.log(newVal); 
      if(newVal)
        this.sel = newVal        
    }, 
  }, 
  mounted () {
    
  },
  methods: {
    choose(item_key){
      // this.selected = item_key;
      this.$emit('choose', item_key);
    }
  }
}
</script>
 


<style scoped>
   
   
.ritem {
  padding: 10px 13px;
  background-color:  var(--un-background-color-gray);;
  border-radius: 53px;
  display: inline-block;
  margin-bottom: 5px;
  /* border: 2px #d0d0d0 solid; */
  cursor: pointer;
  font-weight: bold;
  margin-right: 2px;
}

.ritem:hover {
  background-color:  var(--un-background-color-gray-dark);
}

.ritem.checked {
  background-color: var(--un-primary-back-color);
  color: white;
}

</style>